import React from "react"
import StyledHomeServices from "./style/home-services";
import divider from "../../assets/images/divider.png"
import ServiceList from "./ServiceList";

const HomeServices = ({ data }) => {
    return (
        <StyledHomeServices>
            <div className="wrapper">
                <div className="header">
                    <h2 className="heading">Vendor Categories</h2>
                    <h3 className="sub-heading">ALL YOUR EVENTS TAKEN CARE OF</h3>
                    <img className="mx-auto" src={divider} alt="" />
                    <p className="description">
                        We pride our vendors on handling everything for our clients
                    </p>
                </div>
            </div>
            <ServiceList data={data} />
        </StyledHomeServices>
    )
};

export default HomeServices
