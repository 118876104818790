import React from "react"
import 'swiper/css'
import "swiper/css/navigation"
import StyledBanner from "./style/banner"
import { useState } from 'react'
import SelectSearch from "react-select-search"
import serviceData from "../../util/services.json"
import "../common/style/style.css"
import bgImg from "../../assets/images/home/banner.jpg"
import { TypeAnimation } from 'react-type-animation'
import { navigate } from "gatsby"

const Banner = () => {
    const [service, setService] = useState(null);
    function renderFriend(props, option, snapshot, className) {
        return (
            <button {...props} className={className} type="button">
                <span className="flex flex-row gap-2">
                    <i className={`icon-${option.icon} icon`}></i>
                    <span>{option.name}</span>
                </span>
            </button>
        );
    }


    const toKebabCase = (str) => {
        return str
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map((x) => x.toLowerCase())
            .join("-");
    };
    return (
        <StyledBanner style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="heading-wrapper">
                <h1>Explore The Categories</h1>
                <TypeAnimation
                    sequence={[
                        // Same String at the start will only be typed once, initially
                        'Event Venues',
                        1500,
                        'Catering',
                        1500,
                        'Purohith & Pandit & Priest',
                        1500,
                        'Live Food Counters',
                        1500,
                        'Decorations',
                        1500,
                        'Nadhaswaram(sannai melam)',
                        1500,
                        'Travel & Tourism',
                        1500,
                        'Photography & Video',
                        1500,
                        'Groom & Bride Make Up',
                        1500,
                        'Musical Troops',
                        1500,
                        'Food & Event Material',
                        1500,
                        'Dance Troupes',
                        1500,
                        'Gift Services',
                        1500,
                        'Manpower',
                        1500,
                        'Costume Designer',
                        1500,
                        'Jewellery & Accessories',
                        1500,
                        'Hotel Services',
                        1500,
                        'Invitations',
                        1500,
                        'Entertainment',
                        1500,
                        'Honeymoon',
                        1500,
                    ]}
                    speed={50}
                    className="main-heading"
                    repeat={Infinity}
                />
            </div>
            <p>
                Discover & Connect With Best Vendors and Partners Around You
            </p>
            <div className="search-wrapper">
                <SelectSearch
                    options={serviceData.services.map((service) => ({
                        value: service.name,
                        name: service.name,
                        icon: service.icon,
                    }))}
                    className="select-search"
                    renderOption={renderFriend}
                    search
                    placeholder="Select Category"
                    onChange={setService}
                    value={service}
                    emptyMessage="No Vendor Found" />
                <button disabled={!service} onClick={() => { navigate(`/${toKebabCase(service)}/`) }} className={`cta ${service ? 'active' : ''}`}>
                    Search Vendors
                </button>
            </div>
        </StyledBanner>
    )
};

export default Banner

