import React from "react"
import StyledAbout from "./style/about"
import { Parallax } from "react-parallax"
import bgImg from "../../assets/images/home/about-parallax.jpg"
import CTA from "../common/CTA"

const About = () => {
    return (
        <StyledAbout>
            <Parallax bgImage={bgImg} strength={500} className="parallax">
                <div className="main-wrapper">
                    <div className="parallax-item">
                        <h2>About Us</h2>
                        <p>
                            Housefull Events and Hospitality Management Services is a company that provide a range of services like planning, organizing, Providing Vendors and managing events to the customers. These services include event planning, venue selection, catering, audiovisual equipment rental, entertainment, Logistics, marketing, and all kinds of Services and Support.
                        </p>
                        <CTA name="Learn More"
                            border="border-primary"
                            color="text-primary"
                            textHover="hover:text-black"
                            bgHover="hover:bg-primary"
                            link="/about"
                        />
                    </div>
                </div>
            </Parallax>
        </StyledAbout>
    )
};

export default About
