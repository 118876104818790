import tw, { styled } from 'twin.macro';

const StyledServicesList = styled.section`
${tw`md:py-24 py-12 bg-amber-100/50`};
.main-container {
    ${tw`mx-auto grid px-5`}

    .list-wrapper {
        ${tw`grid md:gap-8 gap-2 xl:grid-cols-6 lg:grid-cols-4 grid-cols-2`}

        .item {
            .main {
                ${tw`flex flex-col items-center justify-start md:(gap-x-2 gap-y-4) gap-x-1 gap-y-2`}
                .icon-wrapper {
                    ${tw`md:(w-28 h-28) w-20 h-20 bg-[#fff5f4] flex items-center justify-center rounded-full shadow-lg`}
                    .icon {
                        ${tw`text-secondary md:text-5xl text-3xl`}
                    }
                }
                span {
                    ${tw`md:text-base text-sm text-center font-medium [word-wrap: break-word]`}
    
                    &.count {
                        ${tw`text-gray-600`}
                    }
                }
            }
        }
    }
}
`;
export default StyledServicesList