import React from "react"
import StyledHomeInspiratons from "./style/home-inspirations"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FaArrowRight, FaArrowLeft } from "react-icons/fa"

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className="next-arrow"
            onClick={onClick}
        >
            <FaArrowRight />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="prev-arrow"
            onClick={onClick}
        >
            <FaArrowLeft />
        </div>
    );
}

const HomeInspirations = ({ inspirations }) => {
    const data = [];
    inspirations.edges.forEach(edge => {
        data.push({
            slug: edge.node.frontmatter.slug,
            title: edge.node.frontmatter.title,
            description: edge.node.frontmatter.description,
            image: edge.node.frontmatter.bannerImage.publicURL
        })
    });
    var settings = {
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <StyledHomeInspiratons>
            <div className="main-container">
                <h2>
                    Inspirations
                </h2>
                <p>
                    Get inspired with the latest trends and advice from our experts
                </p>
                <div className="wrapper">
                    <Slider {...settings}>
                        {data.map((d, i) => (
                            <div className="inspiration-item" key={i}>
                                <h3>{d.title}</h3>
                                <div className="content">
                                    <p>
                                        {d.description}
                                    </p>
                                    <a className="cta" href={d.slug}>
                                        Read More
                                        <FaArrowRight />
                                    </a>
                                </div>
                                <div
                                    className="bg-image"
                                    style={{
                                        backgroundImage: `url(${d.image})`,
                                    }}
                                ></div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </StyledHomeInspiratons>
    )
};

export default HomeInspirations