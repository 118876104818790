import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import Banner from "../components/home/Banner"
import About from "../components/home/About"
import HomeServices from "../components/home/HomeServices"
import HowItWorks from "../components/home/HowItWorks"
import HomeInspirations from "../components/home/HomeInspirations"
import tw, { styled } from 'twin.macro'
import { FaArrowRight, FaCalendarAlt, FaClock } from "react-icons/fa"

const StyledHomeBlog = styled.section`
${tw`md:py-32 py-12 bg-primary bg-opacity-5`};
.main-container {
  ${tw`flex flex-col items-center justify-center gap-2`};
  h2 {
      ${tw`md:text-4xl text-3xl font-bold text-center`}
  }
  > p {
      ${tw`font-medium px-4 text-center`}
  }

  .list {
    ${tw`grid grid-cols-1 lg:(px-8 grid-cols-3 py-5 gap-8) container mx-auto px-4 gap-6 bg-transparent text-black`} 
    .card-wrapper {
        ${tw`bg-white shadow-lg rounded-lg flex flex-col overflow-hidden relative [border: 1px solid #ddd]`}

        figure {
            ${tw`w-full overflow-hidden relative`}

            img {
                ${tw`w-full h-60 [transform: translateZ(0)] [transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms]`}
            }

            &::after {
                ${tw`[content: ''] h-full w-full bottom-0 top-0 absolute z-[1] [background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5))]`}
            }
        }

        .content {
            ${tw`flex flex-col p-6 gap-3 text-[#4a4a4a] h-[calc(100% - 15rem)] relative`}

            h2 {
                ${tw`text-xl font-medium`}

                a {
                    ${tw`text-primary-dark`}
                }
            }

            a {
                ${tw`text-secondary flex flex-row gap-2 items-center`}
            }

            ul.meta {
                ${tw`flex flex-row justify-between`}
                li {
                    ${tw`flex flex-row gap-1 items-center text-dark-muted`}
                }
            }
        }

        &:hover {
            ${tw`[transform: translate3d(0, -2px, 0)]`}
            figure {
                img {
                    ${tw`[transform: scale(1.12)]`}
                }
            }
        }
    }
  }
  .blog-cta {
    ${tw`bg-secondary rounded-full shadow gap-3 px-4 py-3 justify-center text-white font-medium flex items-center`}
  }
}
`;
export const pageQuery = graphql`
  query HomeQuery {
    inspirations: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "inspiration-details" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            template
            title
            description
            bannerImage {
              publicURL
            }
          }
        }
      }
    }
    servicesGroup: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "vendor-details" } } }
    ) {
      group(field: frontmatter___services) {
        fieldValue
        totalCount
      }
    }
    blogListQuery: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } } }
      limit: 3
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            featuredImage {
                publicURL
            }
          }
          timeToRead
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { servicesGroup, inspirations, blogListQuery } = data // data.markdownRemark holds your post data
  const blogList = [];
  blogListQuery.edges.forEach(edge => {
    blogList.push({
      date: edge.node.frontmatter.date,
      description: edge.node.frontmatter.description,
      slug: edge.node.frontmatter.slug,
      title: edge.node.frontmatter.title,
      image: edge.node.frontmatter.featuredImage ? edge.node.frontmatter.featuredImage.publicURL : "",
      time: edge.node.timeToRead
    })
  });
  return (
    <Layout bgWhite={false}>
      <Banner />
      <HomeServices data={servicesGroup} />
      <About />
      <HowItWorks />
      <HomeInspirations inspirations={inspirations} />
      <StyledHomeBlog>
        <div className="main-container">
          <h2>Latest in Blog</h2>
          <p></p>
          <div className="list">
            {blogList.map((d, i) => {
              return (
                <div className="card-wrapper" key={i}>
                  <figure>
                    <img src={d.image} alt="" />
                  </figure>
                  <div className="content">
                    <ul className="meta">
                      <li>
                        <FaCalendarAlt className="icon" />
                        <span>
                          {d.date}
                        </span>
                      </li>
                      <li>
                        <FaClock className="icon" />
                        <span>
                          {d.time} Min.
                        </span>
                      </li>
                    </ul>
                    <h2>
                      <a href={d.slug}>
                        {d.title}
                      </a>
                    </h2>
                    <p>
                      {d.description}
                    </p>
                    <a href={d.slug}>
                      Read More
                      <FaArrowRight />
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
          <Link to="/blog" className="blog-cta">
            <span>
              View All
            </span>
            <FaArrowRight />
          </Link>
        </div>
      </StyledHomeBlog>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="Housefull Events & Hospitality Management Services" description="All vendor categories cover a wide range of services related to events and hospitality, making it easier to find the right vendor for your specific needs." />
)

export default HomePage
