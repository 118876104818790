import React from "react"
import StyledHowItWorks from "./style/how-it-works";
import { BiCategory } from "react-icons/bi"
import { AiOutlineShop } from "react-icons/ai"
import { TbHandClick } from "react-icons/tb"
import dashLine from "../../assets/images/shapes/dashed-line.svg"

const HowItWorks = () => {
    return (
        <StyledHowItWorks>
            <div className="main-container">
                <div className="head-wrapper">
                    <div className="sub-title">FIND HERE</div>
                    <h2>How Does It <span> Work</span></h2>
                    <p>Easy steps in few moments</p>
                </div>
                <div className="list-wrapper">
                    <img src={dashLine} className="line" alt="" />
                    <ul className="list">
                        <li>
                            <div className="icon">
                                <BiCategory />
                                <div className="number">01</div>
                            </div>
                            <h3>Find A Category</h3>
                            <p>These categories encompass a wide range of vendors that can help with various aspects of event planning and execution. If you have a specific type of vendor in mind or if you need more information about any of these categories, feel free to Reach us!</p>
                        </li>
                        <li>
                            <div className="icon">
                                <AiOutlineShop />
                                <div className="number">02</div>
                            </div>
                            <h3>Contact Few Vendors</h3>
                            <p>Check the vendor’s experience and expertise in the event industry . Check the services offered by the vendor and make sure they align with your event requirements. Then contact the vendors. If you need more information about any of these vendors, feel free to reach us!</p>
                        </li>
                        <li>
                            <div className="icon">
                                <TbHandClick />
                                <div className="number">03</div>
                            </div>
                            <h3>Make A Decision</h3>
                            <p>Ultimately, the decision for your vendor and event depends on your specific preferences, needs, and the information you gather during your research.
                                if you need more information about any of these categories, feel free to reach us!</p>
                        </li>
                    </ul>
                </div>
            </div>
        </StyledHowItWorks>
    )
};

export default HowItWorks
