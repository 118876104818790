import tw, { styled } from 'twin.macro';

const StyledBanner = styled.section`
${tw`h-screen bg-cover bg-no-repeat flex items-center justify-center flex-col px-2 z-[1] relative`};
&:before {
  ${tw`w-full h-full left-0 top-0 absolute z-0 [content: ""] [background-image: linear-gradient(to right, rgba(41, 6, 150, 0.9), rgba(134, 11, 19, 0.9))]`}
}
.heading-wrapper {
  ${tw`w-full md:text-5xl text-3xl text-white font-semibold z-[1] flex flex-col gap-4 items-center pb-4 text-center`}
  span {
    ${tw`text-secondary md:text-3xl text-xl font-medium bg-white p-2 leading-none rounded`}
  }
}

p {
  ${tw`text-white md:text-2xl text-xl z-[1] pb-12 text-center`}
}
.search-wrapper {
  ${tw`z-[1] flex md:flex-row flex-col gap-4 p-2 rounded-md bg-white [box-shadow: 0px 0px 0px 8px rgba(255,255,255,0.2)]`}

  .cta {
    ${tw`bg-secondary bg-opacity-50 rounded p-3 justify-center text-white font-medium flex items-center`}

    &.active {
      ${tw`bg-opacity-100`}
    }
  }
}

.icon {
  ${tw`leading-tight`}
}
`;

export default StyledBanner;
