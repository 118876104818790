import tw, { styled } from 'twin.macro';

const StyledHomeServices = styled.section`
${tw`flex flex-col`};
.wrapper {
    ${tw`py-16 bg-dark relative`}

    .header {
        ${tw`container mx-auto grid items-center justify-center text-center gap-8`}
        .heading {
            ${tw`text-primary text-4xl`}
        }
        .sub-heading {
            ${tw`text-white tracking-[.5em] text-lg font-medium`}
        }
        .description {
            ${tw`text-white opacity-90 italic text-sm`}
        }
    }

    // &:after {
    //     ${tw`w-0 h-0 top-0 left-[50%] translate-x-[-50%] absolute [content: ""] [border-left: 300px solid transparent] [border-right: 300px solid transparent] [border-top: 50px solid #fff]`}
    // }
}
`;
export default StyledHomeServices