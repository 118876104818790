import tw, { styled } from 'twin.macro';
const StyledAbout = styled.section`
${tw`grid items-center`}
.parallax {
    ${tw`relative`}
}
.main-wrapper {
    ${tw`grid items-center container mx-auto px-10 md:py-32 py-8`}
    &:before {
        ${tw`w-full h-full left-0 top-0 absolute z-0 [content: ""] [background-color: rgba(0,0,0,.7)]`}
    }
    .parallax-item {
        ${tw`flex flex-col items-center justify-center z-[1] relative`}
        h2 {
            ${tw`md:text-4xl text-2xl font-extrabold text-center p-2 bg-primary text-dark z-[1]`}
        }
        p {
            ${tw`text-white text-lg text-center py-6`}
        }
    }
}
`;
export default StyledAbout;