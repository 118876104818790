import tw, { styled } from 'twin.macro';

const StyledHomeInspiratons = styled.section`
${tw`md:py-32 py-12 bg-amber-100/50 bg-opacity-5`};
.main-container {
    ${tw`flex flex-col items-center justify-center gap-2`};
    h2 {
        ${tw`md:text-4xl text-3xl font-bold text-center`}
    }
    > p {
        ${tw`font-medium px-4 text-center`}
    }
    .wrapper {
        ${tw`w-full md:pt-8 pt-4`}

        .slick-slider {
            ${tw`mx-8`}
            .slick-list {
                .slick-track {
                    ${tw`flex`}
                }
    
                .slick-slide {
                    ${tw`h-auto p-3`}
    
                    > div {
                        ${tw`h-full`}
                    }
                }
            }
        }

        .inspiration-item {
            ${tw`flex flex-col gap-4 bg-white rounded-md h-full p-8 shadow-[2px 2px 8px 0px rgba(2, 45, 98, 0.1)] relative overflow-hidden`}
            h3 {
                ${tw`text-primary-dark text-lg font-medium leading-tight`}
            }
            .content {
                ${tw`flex flex-col leading-loose pt-4`}
                p {
                  ${tw`font-medium`}
                }
                .cta {
                  ${tw`px-4 py-2 flex flex-row justify-center items-center gap-4 bg-secondary rounded-full text-white absolute opacity-0 z-10 left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] font-medium`}
                  &:hover {
                    ${tw`bg-white text-secondary`}
                  }
                }
            }
            .bg-image {
                ${tw`absolute left-0 top-0 w-full h-full opacity-0 bg-cover bg-center transition-all duration-[0.3s] scale-100`}
        
                &:before {
                  ${tw`[content: ''] absolute left-0 top-0 bg-primary bg-opacity-50 z-[9] w-full h-full`}
                }
              }
              &:hover {
                ${tw`cursor-pointer`}
                .content {
                  .cta {
                    ${tw`opacity-100`}
                  }
                }
                .bg-image {
                  ${tw`opacity-100 scale-110`}
                }
              }
        }
    
        .prev-arrow,
        .next-arrow {
            ${tw`text-white bg-primary-dark p-2 text-xl rounded-full cursor-pointer absolute top-[calc(50% - 2rem)] z-[1] block`}
    
            &:hover {
              ${tw`text-opacity-100`}
            }
        }
        
        .prev-arrow {
          ${tw`md:-left-2 -left-8`}
        }
    
        .next-arrow {
          ${tw`md:-right-2 -right-8`}
        }
      }
}
`
export default StyledHomeInspiratons