import tw, { styled } from 'twin.macro';

const StyledHowItWorks = styled.section`
${tw`md:py-24 py-12`};
.main-container {
    ${tw`mx-auto container grid px-5`}
    .head-wrapper {
        ${tw`flex flex-col gap-2 items-center`}

        .sub-title {
            ${tw`uppercase font-semibold [letter-spacing: .125em] text-secondary`}
        }

        h2 {
            ${tw`md:text-4xl text-3xl font-bold text-center`}

            span {
                ${tw`font-black text-primary`}
            }
        }

        p {
            ${tw`font-medium`}
        }
    }

    .list-wrapper {
        ${tw`relative flex items-center justify-center md:mt-16 mt-8`}
        .list {
            ${tw`grid md:grid-cols-3 grid-cols-1 md:gap-24 gap-8`}
    
            li {
                ${tw`flex flex-col md:gap-4 gap-2 items-center`}
    
                p {
                    ${tw`text-center text-pragraph-color`}
                }
    
                .icon {
                    ${tw`w-32 h-32 text-5xl text-secondary relative flex items-center justify-center z-0`}
    
                    &:before, &:after {
                        ${tw`absolute [content: ''] w-full h-full rounded-2xl transition-all`}
                    }
    
                    &:before {
                        ${tw`z-[-1] bg-[#f9f9f9]`}
                    }
    
                    &:after {
                        ${tw`bg-[#ececec] rotate-[-8deg] z-[-2] top-2 left-2`}
                    }

                    .number {
                        ${tw`-top-5 -right-5 z-[1] w-10 h-10 text-base text-dark-muted rounded-full flex items-center justify-center transition-all absolute [border: 2px solid transparent] bg-white [box-shadow: 0 10px 30px rgba(100,100,100,.2)]`}
                    }
                }
    
                h3 {
                    ${tw`font-semibold text-2xl pt-6 text-[#111111] text-center`}
                }

                &:hover {
                    .icon {
                        ${tw`text-white`}
        
                        &:before, &:after {
                            ${tw`bg-secondary`}
                        }
                        .number {
                            ${tw`border-secondary [box-shadow: 0 10px 30px rgba(text-secondary,.2)]`}
                        }
                    }
                }
            }
        }
        .line {
            ${tw`absolute top-2 w-full md:block hidden`}
        }
    }
}
`;
export default StyledHowItWorks