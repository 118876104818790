import React from "react"
import StyledServicesList from "./style/service-list"
import serviceData from "../../util/services.json"
import { Link } from "gatsby"

const toKebabCase = (str) => {
    return str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x) => x.toLowerCase())
        .join("-");
};

const ServiceList = ({ data }) => {
    var mergedServices = [];
    serviceData.services.forEach(function (mainObject) {
        for (let i = 0; i < data.group.length; i++) {
            if (data.group[i].fieldValue === mainObject.name) {
                mergedServices.push({
                    name: mainObject.name,
                    icon: mainObject.icon,
                    sort: mainObject.sort,
                    totalCount: data.group[i].totalCount
                });
            }
        }
    });
    return (
        <StyledServicesList>
            <div className="main-container">
                <div className="list-wrapper">
                    {mergedServices.map((service) => (
                        <div className="item" key={service.sort}>
                            <Link to={`/${toKebabCase(service.name)}/`} className="main">
                                <div className="icon-wrapper">
                                    <i className={`icon-${service.icon} icon`}></i>
                                    {/* <i className="icon-nadhaswaram icon"></i> */}
                                </div>
                                <span>
                                    {service.name}
                                    <span className="count"><br />({service.totalCount})</span>
                                </span>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </StyledServicesList>
    )
};

export default ServiceList
